import React from "react";

const BaseLink = ({
                      children,
                      strikeThrough: highlight = false,
                      style = {},
                      ...others
                  }) => {


    const linkStyle = {
        textDecoration: "none",
        color: "inherit"
    };

    const linkContainerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };

    const highLight = {
        color: '#01c1f0'
    }


    return (
        <a style={{...linkStyle, ...style}} {...others}>
            <div style={{
                ...linkContainerStyle,
                ...(highlight ? highLight : {})
            }}>
                <div>{children}</div>
            </div>
        </a>
    );
};
export default BaseLink;
