import React, { useState } from "react";
import BaseLink from "./BaseLink";
import theme from "../styleguide/theme";

const StrikeThroughOnHoverNavLink = ({ to, exact, children, style = {} }) => {
  const [mouseIn, setMouseIn] = useState(false);

  const innerStyle = {
    color: theme.palette.primary.main,
    ...theme.typography.button
  };

  return (
    <BaseLink
      onMouseEnter={() => setMouseIn(true)}
      onMouseLeave={() => setMouseIn(false)}
      style={{ ...innerStyle, ...style }}
      strikeThrough={mouseIn}
    >
      {children}
    </BaseLink>
  );
};
export default StrikeThroughOnHoverNavLink;
