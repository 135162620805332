import React from "react";
import { Box } from "@material-ui/core";
import { Body2, H5 } from "../../../shared/styleguide/textStyles";
import useHover from "../../../../hooks/useHover";
import Experience from "../../../../domain/experiences/Experience";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ExperienceTileOverlay = ({ work }) => {
  const [hoverRef, isHovered] = useHover();

  const experienceTileOverlayStyle = {
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.6)",
    position: "absolute",
    opacity: isHovered ? 1 : 0
  };

  const labelStyle = {
    color: "white",
    marginBottom: "0px"
  };

  const shortDescriptionStyle = {
    color: "white",
    fontWeight: "500",
    marginBottom: "0px"
  };

  const dividerStyle = {
    width: "100%",
    background: "white",
    height: "2px"
  };

  return (
    <Box
      ref={hoverRef}
      style={experienceTileOverlayStyle}
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <H5 style={labelStyle}>{work.title}</H5>
        <div style={dividerStyle} />
        <Body2 style={shortDescriptionStyle}>{work.subtitle}</Body2>
      </Box>
    </Box>
  );
};

const ExperienceTile = ({ experience, to }) => {
  const experienceTileStyle = {
    background: experience.color,
    width: "100%",
    height: "192px",
    position: "relative"
  };

  return (
    <Link to={to}>
      <Box
        style={experienceTileStyle}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <ExperienceTileOverlay work={experience} />

        <img src={experience.icon} alt={experience.label} />
      </Box>
    </Link>
  );
};

ExperienceTile.propTypes = {
  experience: PropTypes.instanceOf(Experience).isRequired
};

export default ExperienceTile;
