import React from "react";
import Grid from "@material-ui/core/Grid";
import cvSrc from "./cv.pdf";
import HighlightOnHoverLink from "../links/HighlightOnHoverLink";

const linkedinLink = "https://www.linkedin.com/in/lagudelog/";
const mediumLink = "https://lagudelog.medium.com/";

const Contacts = () => {
  return (
    <Grid container spacing={3}>
      <Grid item>
        <HighlightOnHoverLink href={cvSrc}>Resume</HighlightOnHoverLink>
      </Grid>
      <Grid item>
        <HighlightOnHoverLink href={linkedinLink}>
          Linkedin
        </HighlightOnHoverLink>
      </Grid>
      <Grid item>
        <HighlightOnHoverLink href={mediumLink}>
          Medium
        </HighlightOnHoverLink>
      </Grid>
    </Grid>
  );
};

Contacts.propTypes = {};

export default Contacts;
