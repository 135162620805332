import logoSrc from "./imgs/elsevier-icon.svg";
import header1Src from "./imgs/header1.jpg";
import header2Src from "./imgs/header2.jpg";
import header3Src from "./imgs/header3.jpg";
import grid1Src from "./imgs/grid1.jpg";
import grid2Src from "./imgs/grid2.jpg";
import grid3Src from "./imgs/grid3.jpg";
import grid4Src from "./imgs/grid4.jpg";
import grid5Src from "./imgs/grid5.jpg";

import Experience, {WORK_EXPERIENCE_TYPE} from "../Experience";

const elsevierData = {
    id: "elsevier",
    type: WORK_EXPERIENCE_TYPE,
    color: "#eceff1",
    icon: logoSrc,
    title: "Elsevier",
    subtitle: "Empowering Knowledge",
    tags: "",
    headline: "During my time at Elsevier, I was part of the design team dedicated to Reaxys®, a comprehensive web platform providing chemistry data and chemical literature, used by diverse scientific and research institutions.",
    highlight:
        "Copyright © 2020 Elsevier Life Sciences IP Limited. Reaxys® is a trademark of Elsevier Life Sciences IP Limited, used under license.",
    description:
        "<p>In close collaboration with the Reaxys' product owner, my main goal was to understand the challenges of users when working with complex datasets and information visualization solutions, in order translate them into efficient interfaces, while following the brand guidelines. Additionally, I also supported design and research projects for other Elsevier products used by the pharmaceutical industry.</p>" +
        "<p>I was often involved in user research and co-creation sessions with customers and stakeholders from across the globe, using different techniques for rapid prototyping and user testing. I worked in many cross-department efforts: with the Front-End development team to improve accessibility and consistency, the Business Analytics team in the process of introducing new features and measuring usage, and the customer care team to understand users pain points and possible improvements.</p>",
    headerCaption:
        "Wireframes, accessibility options and elements from visual design work.",
    headerPictures: [header1Src, header2Src, header3Src],
    gridPictures: [grid1Src, grid2Src, grid3Src, grid4Src, grid5Src]
};

const elsevier = new Experience();
elsevier.fromData(elsevierData);

export default elsevier;