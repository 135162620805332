import logoSrc from "./imgs/ty-icon.svg";
import header1Src from "./imgs/header1.jpg";
import header2Src from "./imgs/header2.jpg";
import header3Src from "./imgs/header3.jpg";
import grid1Src from "./imgs/grid1.jpg";
import grid2Src from "./imgs/grid2.jpg";
import grid3Src from "./imgs/grid3.jpg";
import grid4Src from "./imgs/grid4.jpg";
import grid5Src from "./imgs/grid5.jpg";

import Experience, { WORK_EXPERIENCE_TYPE } from "../Experience";

const trusyouData = {
  id: "trustyou",
  type: WORK_EXPERIENCE_TYPE,
  color: "#b0bec5",
  icon: logoSrc,
  title: "TrustYou",
  subtitle: "The Power to Listen",
  tags: "Interaction Design, User Research, Data Visualization, Visual Design",
  headline:
    "At TrustYou, I was responsible for our core analytics product and our mobile app. Interaction design, information visualization, visual design and user research were some of my focus areas to create insightful and impactful experiences that enable our customers from the hospitality industry to make data driven decisions.",
  highlight: "Copyright © 2020 TrustYou GmbH",
  description:
    "<p>I was constantly involved in different product development stages, often leading our user research initiatives by planning, conducting and analysing formative and evaluative studies. One of my most important contributions was to establish a better process and toolkit to plan and conduct user research, making easier to track and measure its impact on product development. </p>" +
    "<p>During my time at TrustYou, I also helped to grow and improve Brew, our UI design system,  and to streamline our collaboration with the Engineering and Product teams. I also participated in many cross-department collaborations with the Marketing team, supporting them with projects related with branding, graphic design and illustration.</p>",
  headerCaption:
    "Wireframes for mobile app, marketing illustrations and mobile app design.",
  headerPictures: [header1Src, header2Src, header3Src],
  gridPictures: [grid1Src, grid2Src, grid3Src, grid4Src, grid5Src],
  caseStudies: {
    "Mobile App":
      "https://lagudelog.medium.com/trustyou-mobile-app-a-complete-revamp-of-the-interface-and-user-experience-2412e458cdc8",
    "User Research":
      "https://lagudelog.medium.com/establishing-user-research-as-an-integral-part-of-our-product-development-e0f9aac07b4c",

  }
};

const trustyou = new Experience();
trustyou.fromData(trusyouData);

export default trustyou;
