import * as React from "react";
import { useEffect } from "react";
import WorkDetail from "./WorkDetail";
import ExperienceService from "../../../domain/experiences/ExperienceService";
import { withRouter } from "react-router-dom";

export const loadExperienceAsync = async (
  experienceId,
  experiencesService,
  setExperience
) => {
  const experience = await experiencesService.getExperience(experienceId);

  setExperience(experience);
};

// Container, uses react hooks to provide data
const WorkDetailContainer = props => {
  const experienceId = props.match.params.experienceId;
  const experiencesService = new ExperienceService();

  const [experience, setExperience] = React.useState(null);

  useEffect(() => {
    loadExperienceAsync(experienceId, experiencesService, setExperience);
  }, [experienceId, experiencesService, setExperience]);

  if (experience) {
    return <WorkDetail work={experience} />;
  }

  return <div>...loading</div>;
};

export default withRouter(WorkDetailContainer);
