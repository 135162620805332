import logoSrc from "./imgs/mkit-icon.png";
import header1Src from "./imgs/header1.jpg";
import header2Src from "./imgs/header2.jpg";
import header3Src from "./imgs/header3.jpg";
import grid1Src from "./imgs/grid1.jpg";
import grid2Src from "./imgs/grid2.jpg";
import grid3Src from "./imgs/grid3.jpg";
import grid4Src from "./imgs/grid4.jpg";
import grid5Src from "./imgs/grid5.jpg";
import Experience, { WORK_EXPERIENCE_TYPE } from "../Experience";

const mkitData = {
  id: "mkit",
  type: WORK_EXPERIENCE_TYPE,
  color: "#010e1f",
  icon: logoSrc,
  title: "Mkit Digital",
  subtitle: "Custom Brands & Web Apps",
  tags: "Mobile Interaction Design, Information Architecture, Visual Design",
  headline:
    "At Mkit Digital, I worked as the lead designer, involved in projects from inception to launch. I was in charge of the design of custom digital solutions and brand experiences for products related with science, technology, education, and venture capital funds.",
  highlight:
    "Branding and visual design work for Mkit Digital and Mkit Digital clients, in collaboration with Andrés Agudelo." +
    " Copyright ©2020 Mkit Digital.",
  description:
    "<p>In close collaboration with the company’s founder, I was often part of the initial project conception stages, helping to create and shape brands that will then evolve into a digital product or service. Our main goal was to create modern online experiences and collaborating on projects that were impactful and made a difference for communities, families and entrepreneurs. During my time at Mkit Digital, I worked with a talented team of software engineers, marketing and communication experts, in a fully remote environment.</p>" +
    "<p>Besides the projects with diverse international clients, one of my biggest contributions to Mkit Digital was the complete revamp of their branding, including the company’s logo, website, branding guidelines, and design system. Our aim with this new identity was to represent trusting, technology and curiosity.</p>",
  headerPictures: [header1Src, header2Src, header3Src],
  headerCaption:
    "Elements from branding, company website and marketing material.",
  gridPictures: [grid1Src, grid2Src, grid3Src, grid4Src, grid5Src]
};

const mkit = new Experience();
mkit.fromData(mkitData);

export default mkit;
