import React from "react";
import Grid from "@material-ui/core/Grid";
import logo from "./logo.svg";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import HighlightOnActivePageNavLink from "../links/HighlightOnActivePageNavLink";

const logoStyle = {
  width: "100px",
  height: "62px",
  display: "flex"
};

const TopBar = () => {
  return (
    <Box mt={3} mb={3}>
      <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
        <Grid item xs={6} sm={8} md={10}>
          <Link to="/">
            <img style={logoStyle} src={logo} alt="Laura Agudelo"></img>
          </Link>
        </Grid>
        <Grid item xs={3} sm={2} md={1}>
          <HighlightOnActivePageNavLink exact={true} to="/work">
            Work
          </HighlightOnActivePageNavLink>
        </Grid>
        <Grid item xs={3} sm={2} md={1}>
          <HighlightOnActivePageNavLink exact={true} to="/about">
            About
          </HighlightOnActivePageNavLink>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopBar;
