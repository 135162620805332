import { ACADEMIC_EXPERIENCE_TYPE, WORK_EXPERIENCE_TYPE } from "./Experience";

import materialize from "./materialize/Materialize";
import turtlebot from "./turtlebot/Turtlebot";
import trustyou from "./trustyou/TrustYou";
import autoscout24 from "./autoscout24/AutoScout24";
import elsevier from "./elsevier/Elsevier";
import bauhausweg from "./bauhausweg/Bauhausweg";
import rayuela from "./rayuela/Rayuela";
import mkit from "./mkit/Mkit";
import gnam from "./gnam/Gnam";

export default class ExperienceService {
  constructor() {
    this.experiences = [
      autoscout24,
      trustyou,
      elsevier,
      mkit,
      gnam,
      turtlebot,
      materialize,
      rayuela,
      bauhausweg
    ];

    this.experiencesMap = {};

    this.experiences.forEach(experience => {
      this.experiencesMap[experience.id] = experience;
    });
  }

  /**
   * Returns the work experiences asynchronously
   * @returns {Promise<Experience[]>}
   */
  async getWorkExperiences() {
    return new Promise((resolve, reject) => {
      resolve(
        this.experiences.filter(experience => {
          return experience.type === WORK_EXPERIENCE_TYPE;
        })
      );
    });
  }

  /**
   * Returns the Academic experiences asynchronously
   * @returns {Promise<Experience[]>}
   */
  async getAcademicExperiences() {
    return new Promise((resolve, reject) => {
      resolve(
        this.experiences.filter(experience => {
          return experience.type === ACADEMIC_EXPERIENCE_TYPE;
        })
      );
    });
  }

  /**
   * Returns all the experiences asynchronously
   * @returns {Promise<Experience[]>}
   */
  async getAllExperiences() {
    return new Promise((resolve, reject) => {
      resolve(this.experiences);
    });
  }

  /**
   * Returns one experience asynchronously
   * @param experienceId
   * @returns {Promise<Experience>}
   */
  async getExperience(experienceId) {
    return new Promise((resolve, reject) => {
      if (this.experiencesMap.hasOwnProperty(experienceId)) {
        resolve(this.experiencesMap[experienceId]);
      } else {
        reject();
      }
    });
  }
}
