import Typography from "@material-ui/core/Typography";
import React from "react";
import { primary } from "../../../../shared/styleguide/colors";
import { H5, H6, Subtitle1 } from "../../../../shared/styleguide/textStyles";

export const CompanyName = ({ children }) => {
  return <H5 style={{ color: primary }}>{children}</H5>;
};

export const Position = ({ title, startDate, endDate }) => {
  return (
    <div style={{ marginBottom: "0.5rem" }}>
      <H6 variant="h6">{title}</H6>
      <Subtitle1>
        {startDate} - {endDate}
      </Subtitle1>
    </div>
  );
};

export const Description = ({ children }) => {
  return <Typography>{children}</Typography>;
};

export const KeyTechnologiesSection = ({ children }) => {
  return (
    <div style={{ backgroundColor: "#eeeeee", padding: "1rem" }}>
      <Typography>
        <b>Key Technologies:</b> {children}
      </Typography>
    </div>
  );
};
