import Grid from "@material-ui/core/Grid";
import { CaptionLowEmphasis } from "../styleguide/textStyles";
import React from "react";
import Box from "@material-ui/core/Box";

const WithCaption = ({ caption, children }) => {
  return (
    <Box mb={1}>
      <Grid container>
        <Grid item xs={12}>
          {children}
        </Grid>
        <Grid item xs={12} md={8}>
          <CaptionLowEmphasis>{caption}</CaptionLowEmphasis>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WithCaption;
