import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  Body1MediumEmphasis,
  Body2MediumEmphasis,
  H4,
  H5
} from "../../shared/styleguide/textStyles";
import aboutImg from "./about-img.png";
import Divider from "../../shared/divider/Divider";
import Contacts from "../../shared/contacts/Contacts";
import { Hidden } from "@material-ui/core";
import { mainColumnGridSizes } from "../../shared/styleguide/sizes";
import Box from "@material-ui/core/Box";

const TextInlineImg = () => {
  return (
    <Box mb={2}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div>
          <img src={aboutImg} alt="Me" />
        </div>
      </div>
    </Box>
  );
};

const About = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Hidden smUp>
            <TextInlineImg />
          </Hidden>

          <H4>Hola, Hello, Hallo.</H4>

          <Box mt={1}>
            <Body2MediumEmphasis>
              My journey started ten years ago while working for the mobile
              video games industry. At the beginning of my career, I spent some
              time combining 3D design and interface design for casual games,
              and then transitioned to web and mobile projects. Coming from a
              graphic design background, I found it tremendously interesting and
              challenging, that the products that we were building had
              to be more than visually appealing; they had to be easy to use,
              and serve a purpose, and fulfil a need. <br />
              <br />
            </Body2MediumEmphasis>

            <Body2MediumEmphasis>
              Since then, I have been constantly learning and pushing myself to
              understand the challenges, needs, and motivations from humans, in
              order to translate them to impactful products and experiences.
              Seven years ago I moved to Germany to study at the Bauhaus
              University, where I finished a MFA in Media Art and Design that
              brought me closer to Human Computer Interaction and User
              Experience practices. <br />
              <br />
            </Body2MediumEmphasis>
            <Body2MediumEmphasis>
              In a world that never seems to stop and is always reinventing
              itself, I enjoy being busy with all what design encompasses, but
              especially identifying patterns and analysing data and behaviours.
              At the same time, I always try to be conscious of the impact that
              we have on the lives of the ones that are exposed to what we
              create. <br /> <br />
            </Body2MediumEmphasis>
            <Body2MediumEmphasis>
              As a Product designer, and within the full spectrum of user
              experience, I often have to wear many hats and work in different
              tasks, but my main areas of focus are the following:
            </Body2MediumEmphasis>
          </Box>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={4}>
            <img src={aboutImg} alt="Me" />
          </Grid>
        </Hidden>
      </Grid>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <H5>Visual Design</H5>
          <Box mt={1}>
            <Body2MediumEmphasis>
              I have strong foundations in illustration, typography and the
              principles of good communication and information visualization.
              The knowledge and experience that I gained from working on
              branding and editorial projects enable me to create delightful and
              engaging experiences.
            </Body2MediumEmphasis>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <H5>Interaction Design</H5>
          <Box mt={1}>
            <Body2MediumEmphasis>
              Turning problems into simple and functional solutions is one of
              the key areas of my work as a designer. I use different
              methodologies from Human Computer Interaction and User Experience
              practices to gain a deep understanding of users and their needs,
              to ultimately create meaning and purpose.
            </Body2MediumEmphasis>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <H5>User Research</H5>
          <Box mt={1}>
            <Body2MediumEmphasis>
              User research practices and data driven design allows me to
              validate decisions, and add context and insights to the design
              process. However, what I find extremely important from this
              discipline, is that it is a way to ensure that what we design is
              safe, inclusive, and brings a positive impact to people and
              environments.
            </Body2MediumEmphasis>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item {...mainColumnGridSizes}>
          <Box mt={3}>
            <Body1MediumEmphasis>
              In my free time I enjoy practicing yoga, biking, playing some
              video games and dancing in the kitchen with my amazing partner.
            </Body1MediumEmphasis>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Contacts />
      </Box>
    </div>
  );
};
 
export default About;
