import logoSrc from "./imgs/materialize-icon.svg";
import header1Src from "./imgs/header1.jpg";
import header2Src from "./imgs/header2.jpg";
import header3Src from "./imgs/header3.jpg";
import grid1Src from "./imgs/grid1.jpg";
import grid2Src from "./imgs/grid2.jpg";
import grid3Src from "./imgs/grid3.jpg";
import grid4Src from "./imgs/grid4.jpg";
import grid5Src from "./imgs/grid5.jpg";

import Experience, { ACADEMIC_EXPERIENCE_TYPE } from "../Experience";

const materializeData = {
  id: "materialize",
  type: ACADEMIC_EXPERIENCE_TYPE,
  color: "#7c43ff",
  icon: logoSrc,
  title: "Materialize",
  subtitle: "Computer Vision Application",
  tags: "Interaction Design, Computer Vision & Front End Development",
  headline:
    "Materialize is an explorative project to connect the convergent points between Paper Prototyping as an analogue technique for usability testing, and Google Material Design as an unifying theory of a rationalized digital space.",
  highlight:
    "The project was developed as the thesis for my MFA degree at the Bauhaus Universität Weimar, which obtained an honour mention by the chair of Interface Design from the Media Art and Design program.",
  description:
    "<p>The goal of this project was to provide an unified experience across two different platforms, allowing users to build interface layouts and transform them into digital visualizations grounded in the physical world. This was possible through a web camera and a computer vision application using image segmentation, translating a simple paper prototype into card components.</p>" +
    "<p>Materialize intends to reinforce the importance of analogue studies in a way of understanding paradigms brought by new technologies and innovations, and at the same time, encourage further developments in service of usability practices.</p>",
  headerCaption: "Initial project concept and final prototype",
  headerPictures: [header1Src, header2Src, header3Src],
  gridPictures: [grid1Src, grid2Src, grid3Src, grid4Src, grid5Src],
  caseStudies: {'Case study': 'https://lagudelog.medium.com/materialize-connecting-the-convergent-points-between-paper-prototyping-and-google-material-design-867a13ac2551'}
};

const materialize = new Experience();
materialize.fromData(materializeData);

export default materialize;
