import { createMuiTheme } from "@material-ui/core";
import { black85, primary, secondary } from "./colors";

const theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // No more ripple, on the whole application 💣!
    }
  },
  overrides: {
    MuiButton: {
      root: {
        padding: "0px",
        minWidth: "none"
      },
      text: {
        padding: "0px"
      }
    }
  },

  typography: {
    fontFamily: "Heebo",
    color: black85,
    h3: {
      fontSize: "3rem",
      lineHeight: "1.53",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal"
    },
    h4: {
      fontSize: "2.5rem",
      fontWeight: "500"
    },
    h5: {
      fontSize: "1.625rem",
      fontWeight: "500"
    },
    subtitle1: {
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.88",
      letterSpacing: "0.5px"
    },
    body1: {
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.88",
      letterSpacing: "0.5px"
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "2",
      letterSpacing: "normal"
    },
    button: {
      textTransform: "capitalize",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "2",
      letterSpacing: "2px",
      fontSize: "16px"
    },
    caption: {
      marginTop: "10px",
      display: "block"
    }
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primary
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: secondary
    }
  },
  spacing: 12
});

export default theme;
