import React from "react";
import Grid from "@material-ui/core/Grid";
import { Body1MediumEmphasis, H4 } from "../../../shared/styleguide/textStyles";
import { mainColumnGridSizes } from "../../../shared/styleguide/sizes";
import Box from "@material-ui/core/Box";

const Info = () => {
  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item {...mainColumnGridSizes}>
          <H4>Hola, hello, hallo</H4>
          <Box mt={1} mb={1}>
            <Body1MediumEmphasis>
              I’m a Senior Product Designer with ten years of experience, focused on
              integrating visual design, interaction design and user research practices. I work
              to understand the challenges, needs and motivations from users,
              translating them into intuitive and impactful products and
              experiences. <br />
              <br />
            </Body1MediumEmphasis>
            <Body1MediumEmphasis>
              I hold an MFA in Media Art and Design from the Bauhaus Universität
              Weimar and a BA in Graphic Design.
            </Body1MediumEmphasis>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Info;
