import logoSrc from "./imgs/icon.svg";
import header1Src from "./imgs/header1.jpg";
import header2Src from "./imgs/header2.jpg";
import header3Src from "./imgs/header3.jpg";
import grid1Src from "./imgs/grid1.jpg";
import grid2Src from "./imgs/grid2.jpg";
import grid3Src from "./imgs/grid3.jpg";
import grid4Src from "./imgs/grid4.jpg";
import grid5Src from "./imgs/grid5.jpg";

import Experience, { WORK_EXPERIENCE_TYPE } from "../Experience";

const autoscout24Data = {
  id: "autoscout24",
  type: WORK_EXPERIENCE_TYPE,
  color: "#272727",
  icon: logoSrc,
  title: "AutoScout24",
  subtitle: "Design Systems & Ops",
  tags: "Interaction Design, User Research, Data Visualization, Visual Design",
  headline:
    "At AutoScout24, I worked at the intersection of user experience, visual design, branding, and front-end development. I owned user interface topics among a team of 12 designers and researchers, aiming for consistency and scalability across web and mobile.",
  highlight: "Examples of Design System artifacts and documentation. © Copyright 2021 by AutoScout24 GmbH",
  description:
    "<p>During my time here, I led the project to build and mantain Showcar, our Design System. I worked on connecting existing rituals and resources, but also on introducing new practices to enable better cross-functional collaboration across the company. My contributions to this project also involved hands-on work on building reusable components and libraries, as well as defining UI foundations and patterns. The purpose of Showcar was not only to make our products more scalable and faster to deliver, but also to provide a consistent and intuitive experience throughout all the platforms and product verticals. </p>" +
    "<p> As part of my role, I also supported the UX leadership team with management and ownership of design tools and processes, roadmap and capacity assessment, and mentoring and onboarding for other designers, both internals and freelancers. Additionally, I was part of other projects such as our migration from Sketch to Figma, the definition of a user research repository, among others.</p>",
  headerCaption:
    "Design System structure, contribution process and components stickersheet.",
  headerPictures: [header1Src, header2Src, header3Src],
  gridPictures: [grid1Src, grid2Src, grid3Src, grid4Src, grid5Src],
  caseStudies: {
  }
};

const autoscout24 = new Experience();
autoscout24.fromData(autoscout24Data);

export default autoscout24;
