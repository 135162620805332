import logoSrc from "./imgs/turtlebot-icon.svg";
import header1Src from "./imgs/header1.jpg";
import header2Src from "./imgs/header2.jpg";
import header3Src from "./imgs/header3.jpg";
import grid1Src from "./imgs/grid1.jpg";
import grid2Src from "./imgs/grid2.jpg";
import grid3Src from "./imgs/grid3.jpg";
import grid4Src from "./imgs/grid4.jpg";
import grid5Src from "./imgs/grid5.jpg";

import Experience, { ACADEMIC_EXPERIENCE_TYPE } from "../Experience";

const turtlebotData = {
  id: "turtlebot",
  type: ACADEMIC_EXPERIENCE_TYPE,
  color: "#010e1f",
  icon: logoSrc,
  title: "Turtlebot",
  subtitle: "Robot Controller Interface",
  tags: "Mobile Interaction Design, Information Architecture, Visual Design",
  headline:
    "This project is a case study based on a robotic operating system with computer vision capabilities developed by a team of mechatronic engineers as a part of their master in Computer Vision.",
  highlight:
    "Project selected for the Bauhaus Universität Interface Design Showreel on 2015, the Bauhaus Universität Summaery 2016 exhibition, and the Bauhaus Revisited Interface Design Publication on 2016.",
  description:
    "<p>The case study was developed in three stages: Understanding the system capabilities, research of related interfaces, and the final visual design.</p>" +
    "<p>Although the main functionalities of the robot were 3D scanning, mapping and computer vision, the experience from a user perspective was ultimately defined by driving modes that the system was able to provide, which were motion control or patrolling. </p>" +
    "<p>These two ways of operating the system were defined after running a couple of card sorting exercises with other designers and researching similar products such as app enabled toys and drone controllers. As the interface was enabling the connection with an existing product, " +
    "visual design artifacts such as the color palette and the iconography were developed based on the established branding from the Turtlebot products.</p>",
  headerCaption:
    "Wireframes, app icons and project featured in the Bauhaus Revisited Magazine",
  headerPictures: [header1Src, header2Src, header3Src],
  gridPictures: [grid1Src, grid2Src, grid3Src, grid4Src, grid5Src],
  video: "https://vimeo.com/154053970"
};

const turtlebot = new Experience();
turtlebot.fromData(turtlebotData);

export default turtlebot;
