import logoSrc from "./imgs/bauhausweg-icon.svg";
import header1Src from "./imgs/header1.jpg";
import header2Src from "./imgs/header2.jpg";
import header3Src from "./imgs/header3.gif";
import grid1Src from "./imgs/grid1.jpg";
import grid2Src from "./imgs/grid2.jpg";
import grid3Src from "./imgs/grid3.jpg";
import grid4Src from "./imgs/grid4.jpg";
import grid5Src from "./imgs/grid5.jpg";
import Experience, { ACADEMIC_EXPERIENCE_TYPE } from "../Experience";

const bauhauswegDescription = `
<p>
    The purpose of the project was to provide a clear and easy to use navigation to
    enhance the experience of the traditional guided tours and how the involved spaces are perceived.
</p>
<p>
    As part of the explorative research conducted at the beginning of the project, we analyzed and tested
    several products that provide geolocation based experiences such as mobile video games, sports tracking
    systems, platforms with travel timeline features and apps supporting geocaching experiences. This was
    essential to understand how mobile device interactions change when they are experienced outdoors, and
    helped us to craft a product in which the balance between digital content and the physical spaces is
    the key. 
</p>
<p>
    BauhausWeg includes audio guides, historical information and predefined tours. It also allows users
    to create their custom paths by combining points of interest and by choosing desired length and distance.
    Visual elements such as the color palette, iconography and imagery were designed based on the geometrical
    patterns and primary colors characteristic of the Bauhaus movement.
</p>`;

const bauhauswegData = {
  id: "bauhausweg",
  type: ACADEMIC_EXPERIENCE_TYPE,
  color: "#010101",
  icon: logoSrc,
  title: "Bauhausweg",
  subtitle: "Discovering Weimar",
  tags: "Mobile Interaction Design, Information Architecture, Visual Design",
  headline:
    "BauhausWeg is a touristic mobile application designed for the famous Bauhaus Spaziergang tour in Weimar, Germany. We designed an app for guiding the users throughout the city and its cultural sites.",
  highlight:
    "The project was developed with Julian Castillo and Mariana Sanchez for the Interface Design Projektmodul during my master studies at the Bauhaus Universität Weimar.",
  description: bauhauswegDescription,
  headerCaption: "Bauhaus visuals, wireframes and app icon. ",
  headerPictures: [header1Src, header2Src, header3Src],
  gridPictures: [grid1Src, grid2Src, grid3Src, grid4Src, grid5Src],
  video: "https://vimeo.com/174496483"
};

const bauhausweg = new Experience();
bauhausweg.fromData(bauhauswegData);

export default bauhausweg;
