export const WORK_EXPERIENCE_TYPE = "WORK";

export const ACADEMIC_EXPERIENCE_TYPE = "ACADEMIC";

const DEFAULT_EXPERIENCE_TYPE = WORK_EXPERIENCE_TYPE;

export default class Experience {
  id = "";
  type = DEFAULT_EXPERIENCE_TYPE;
  color = "";
  icon = "";
  title = "";
  subtitle = "";
  headline = "";
  description = "";
  highlight = "";
  headerPictures = [];
  gridPictures = [];
  caseStudies = {};
  video = "";

  constructor(
    id,
    color,
    icon,
    title,
    subtitle,
    tags,
    headline,
    description,
    highlight,
    headerCaption,
    headerPictures,
    gridCaption,
    gridPictures,
    video,
    caseStudies
  ) {
    this.fromData({
      id,
      type: this.type,
      color,
      icon,
      title,
      subtitle,
      tags,
      headline,
      description,
      highlight,
      headerCaption,
      headerPictures,
      gridCaption,
      gridPictures,
      video,
      caseStudies
    });
  }

  fromData({
    id,
    type,
    color,
    icon,
    title,
    subtitle,
    tags,
    headline,
    description,
    highlight,
    headerCaption,
    headerPictures,
    gridCaption,
    gridPictures,
    video,
    caseStudies
  }) {
    this.id = id;
    this.type = type;
    this.color = color;
    this.icon = icon;
    this.title = title;
    this.subtitle = subtitle;
    this.tags = tags;
    this.headline = headline;
    this.description = description;
    this.highlight = highlight;
    this.headerCaption = headerCaption;
    this.headerPictures = headerPictures;
    this.gridCaption = gridCaption;
    this.gridPictures = gridPictures;
    this.video = video;
    this.caseStudies = caseStudies !== undefined ? caseStudies : {};
  }
}
