import React from "react";
import {
    H3,
    H5,
    Subtitle1MediumEmphasis
} from "../../shared/styleguide/textStyles";
import {Grid} from "@material-ui/core";
import ExperienceTile from "./experienceTile/ExperienceTile";
import Box from "@material-ui/core/Box";

// Pure component
export const Work = ({works, academic}) => {
    const experiencesGridStyle = {marginBottom: "40px", marginTop: "20px"};

    return (
        <div>
            <H3>Work</H3>
            <Subtitle1MediumEmphasis>
                Projects, learnings and contributions from some of the companies I have
                worked for.
            </Subtitle1MediumEmphasis>
            <Box mt={1} mb={2}>
                <Grid container>
                    {works.map(experience => (
                        <Grid item xs={6} key={experience.id}>
                            <ExperienceTile
                                experience={experience}
                                to={`/work/${experience.id}`}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <H5>Academic & Personal</H5>
            <Subtitle1MediumEmphasis>
                A selection from my most relevant academic and personal projects.
            </Subtitle1MediumEmphasis>
            <Box mt={1} mb={2}>
                <Grid container style={experiencesGridStyle}>
                    {academic.map(experience => (
                        <Grid item xs={6} key={experience.id}>
                            <ExperienceTile
                                experience={experience}
                                to={`/work/${experience.id}`}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
};
