import React from "react";
import { NavLink } from "react-router-dom";

const PlainStyleNavLink = props => {
  const linkStyle = {
    textDecoration: "none",
    color: "inherit"
  };

  return <NavLink style={linkStyle} {...props} />;
};

export default PlainStyleNavLink;
