import React from "react";
import { Grid } from "@material-ui/core";
import { Body2MediumEmphasisSpaced } from "../styleguide/textStyles";

const footerStyle = {
  marginTop: "50px",
  marginBottom: "50px"
};

const Footer = () => {
  return (
    <div style={footerStyle}>
      <Grid container spacing={3}>
        <Grid item sm={3}>
          <Body2MediumEmphasisSpaced>
            Laura Agudelo
            <br />
            Product Designer
          </Body2MediumEmphasisSpaced>
        </Grid>
        <Grid item sm={3}>
          <Body2MediumEmphasisSpaced>
            Munich, Germany
            <br />
            info@laura-agudelo.com
          </Body2MediumEmphasisSpaced>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
