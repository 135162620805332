import React from "react";
import Home from "./components/pages/home/Home";
import Container from "@material-ui/core/Container";
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import TopBar from "./components/shared/topbar/Topbar";
import "./App.css";
import theme from "./components/shared/styleguide/theme";
import {ThemeProvider} from "@material-ui/core";
import Footer from "./components/shared/footer/Footer";
import Divider from "./components/shared/divider/Divider";
import About from "./components/pages/about/About";
import WorkContainer from "./components/pages/work/WorkContainer";
import WorkDetailContainer from "./components/pages/workDetail/WorkDetailContainer";
import ScrollToTop from "./components/shared/scrollToTop/scrollToTop";
import CVPage from "./components/pages/cv/CVPage";

function App() {
    return (
        <div className="App">
            <Router>
                <ThemeProvider theme={theme}>
                    <Switch>
                        <Route exact path="/cv">
                            <CVPage/>
                        </Route>
                        <Route path="/">
                            <Container>
                                <TopBar/>
                                <ScrollToTop/>
                                <Switch>
                                    <Route exact path="/">
                                        <Home/>
                                    </Route>
                                    <Route exact path="/work">
                                        <WorkContainer/>
                                    </Route>
                                    <Route exact path="/work/:experienceId">
                                        <WorkDetailContainer/>
                                    </Route>
                                    <Route exact path="/about">
                                        <About/>
                                    </Route>
                                </Switch>
                                <Divider/>
                                <Footer/>
                            </Container>
                        </Route>
                    </Switch>
                </ThemeProvider>
            </Router>
        </div>
    );
}

export default App;
