import logoSrc from "./imgs/icon.svg";
import header1Src from "./imgs/header1.jpg";
import header2Src from "./imgs/header2.jpg";
import header3Src from "./imgs/header3.jpg";
import grid1Src from "./imgs/grid1.jpg";
import grid2Src from "./imgs/grid2.jpg";
import grid3Src from "./imgs/grid3.jpg";
import grid4Src from "./imgs/grid4.jpg";
import grid5Src from "./imgs/grid5.jpg";

import Experience, { ACADEMIC_EXPERIENCE_TYPE } from "../Experience";

const gnamData = {
  id: "gnam",
  type: ACADEMIC_EXPERIENCE_TYPE,
  color: "#eceff1",
  icon: logoSrc,
  title: "Gnam!",
  subtitle: "Our Custom-made Meal Planner",
  tags: "Interaction Design, Computer Vision & Front End Development",
  headline:
    "Gnam! is a personal project that I'm building with my partner to make more fun and efficient the process of planning our weekly meals and grocery shopping.",
  highlight:
    "Event storming, wireframes, first version and current web and mobile screens",
  description:
    "<p>After a couple of months living together, my partner and I decided to start planning our weekly meals as a way to track what we were eating and reduce our food waste. The way we were doing it at the beginning, was to write down some of our shared recipes on a piece of paper, each with the needed ingredients, and then we were adding the daily meals on a printed weekly planner. Before going grocery shopping, we were manually writing a list of all the ingredients and quantities we needed for each of the recipes of the week. The process was the same every week, taking a lot of time between finding that piece of paper with the recipes, and listing the groceries. </p>" +
    "<p>Living with a software engineer sometimes means, that these manual processes at home don't survive for too long, and as expected, it soon became a script. However, being a designer, I didn't consider the process to be inclusive enough, so I proposed to combine our superpowers to build an app that we both could use. This is how Gnam! was born. We took a couple of weekends to sit down and define all the things we wanted to be included, the script became an API, and we built a mobile-first version using Material UI, initially just changing a couple of primary styles.</p>" +
    "<p>Since then, we have been taking some time here and there to work on improving the user experience and the visual design of the app, including <a href='hhttps://blush.design/collections/the-munchies/the-munchies'>The Munchies illustrations from Blush</a>, a way to add our perceived 'pigginess' level, and a feature that suggests us recipes based on our own usage, among others. After more than one year using the app only ourselves, many of our friends became curious about it, so we added the possibility of creating an account and sharing the plans and recipes with others. This has allowed us to collect feedback, and to adapt the app to cover other possibilities and scenarios based on the dynamics of different households. Gnam! is still not open to everyone, but -who knows!- it might be your tool for planning meals and grocery shopping in the near future.</p>",
  headerCaption: "Weekly planner on paper, first recipe scripts and current web app designs.",
  headerPictures: [header1Src, header2Src, header3Src],
  gridPictures: [grid1Src, grid2Src, grid3Src, grid4Src, grid5Src],
};

const gnam = new Experience();
gnam.fromData(gnamData);

export default gnam;
