import React from "react";
import { CompanyName, Position } from "./sharedComponents";
import { ExperienceContainer } from "../sharedComponents";
import { Body1Narrow } from "../../../../shared/styleguide/textStyles";

const TrustYou = () => {
  return (
    <ExperienceContainer
      title={
        <>
          <CompanyName>TrustYou Gmbh.</CompanyName>
          <Position
            title="Product Designer"
            startDate="Sept 2018"
            endDate="Today"
          />
        </>
      }
    >
      <Body1Narrow>
        At TrustYou, I am responsible for our core analytics product and our
        mobile app. Interaction design, information visualization, visual design
        and user research are some of my focus areas to create insightful and
        impactful experiences that enable our customers from the hospitality
        industry to make data driven decisions.
      </Body1Narrow>
    </ExperienceContainer>
  );
};

export default TrustYou;
