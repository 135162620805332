import React from "react";
import CVHeader from "./CVHeader";
import Divider from "./Divider";
import TrustYou from "./experiences/work/TrustYou";
import Education from "./experiences/education/Education";
import {Container} from "@material-ui/core";


const pdfStyle = {
    pageBreakInside: 'avoid'
};

const CVPage = () => {
    return (
        <Container>
            <CVHeader/>
            <div style={pdfStyle}>
                <Divider/>
                <TrustYou/>
            </div>
            <div style={pdfStyle}>
                <Education/>
            </div>
        </Container>
    )
};


export default CVPage;
