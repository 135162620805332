import React from "react";

const PlainStyleLink = props => {
  const linkStyle = {
    textDecoration: "none",
    color: "inherit"
  };

  return (
    <a style={linkStyle} {...props}>
      {props.children}
    </a>
  );
};

export default PlainStyleLink;
