import React from "react";
import StrikeThroughOnHover from "./HighlightOnHover";
import PlainStyleLink from "../plainStyleLink/plainStyleLink";

const HighlightOnHoverLink = ({ href, children, ...others }) => {
  return (
    <PlainStyleLink href={href} {...others}>
      <StrikeThroughOnHover>{children}</StrikeThroughOnHover>
    </PlainStyleLink>
  );
};
export default HighlightOnHoverLink;
