import React, { useState } from "react";
import BaseLink from "./BaseLink";
import theme from "../styleguide/theme";
import PlainStyleNavLink from "../plainStyleLink/plainStyleNavLink";

const HighlightOnHoverNavLink = ({ to, exact, children, style = {} }) => {
  const [mouseIn, setMouseIn] = useState(false);

  const innerStyle = {
    color: theme.palette.primary.main,
    ...theme.typography.button
  };

  return (
    <PlainStyleNavLink exact={exact} to={to}>
      <BaseLink
        onMouseEnter={() => setMouseIn(true)}
        onMouseLeave={() => setMouseIn(false)}
        style={{ ...innerStyle, ...style }}
        strikeThrough={mouseIn}
      >
        {children}
      </BaseLink>
    </PlainStyleNavLink>
  );
};
export default HighlightOnHoverNavLink;
