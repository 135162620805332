import React from "react";
import Box from "@material-ui/core/Box";

const dividerStyle = {
  height: "1px",
  width: "100%",
  backgroundColor: "#ededed"
};

const Divider = () => (
  <Box mt={2} mb={2}>
    <div style={dividerStyle}></div>
  </Box>
);

export default Divider;
