import { useRouteMatch } from "react-router-dom";
import React from "react";
import BaseLink from "./BaseLink";
import theme from "../styleguide/theme";
import PlainStyleNavLink from "../plainStyleLink/plainStyleNavLink";

const HighlightOnActivePageNavLink = ({
  to,
  exact,
  children,
  style = {}
}) => {
  const match = useRouteMatch({
    path: to,
    exact: false
  });

  const innerStyle = {
    ...theme.typography.body1
  };

  return (
    <PlainStyleNavLink exact={exact} to={to}>
      <BaseLink
        style={{ ...innerStyle, ...style }}
        strikeThrough={match}
      >
        {children}
      </BaseLink>
    </PlainStyleNavLink>
  );
};
export default HighlightOnActivePageNavLink;
