import React from "react";
import Grid from "@material-ui/core/Grid";
import projectsGrid from "./projects-grid.png";
import { H3 } from "../../../shared/styleguide/textStyles";
import Hidden from "@material-ui/core/Hidden";

const Headline = () => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={6}>
        <H3>
          I’m Laura.
          <br />I design products and experiences.
        </H3>
      </Grid>
      <Hidden xsDown>
        <Grid item sm={6}>
          <img
            style={{ height: "100%" }}
            src={projectsGrid}
            alt="My projects"
          />
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item sm={6} style={{ height: "100px", overflow: "hidden" }}>
          <img
            style={{
              position: "relative",
              width: "120%",
              left: "-5%",
              top: "-59%"
            }}
            src={projectsGrid}
            alt="My projects"
          />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Headline;
