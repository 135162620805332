import React from "react";
import { Typography } from "@material-ui/core";
import { black35, black50 } from "./colors";

const mediumEmphasisStyle = {
  color: black50
};

const lowEmphasisStyle = {
  color: black35
};

const whiteStyle = {
  color: "white"
};

const narrowStyle = {
  letterSpacing: "0.15px"
};

const spacedStyle = {
  fontWeight: "normal",
  letterSpacing: "1.75px"
};

export const H3 = props => {
  return <Typography variant="h3" {...props} />;
};

export const H4 = props => {
  return <Typography variant="h4" {...props} />;
};

export const H5 = props => {
  return <Typography variant="h5" {...props} />;
};

export const H5White = props => {
  return <Typography style={whiteStyle} variant="h5" {...props} />;
};

export const H5MediumEmphasis = props => {
  return <Typography style={mediumEmphasisStyle} variant="h5" {...props} />;
};

export const H5LowEmphasis = props => {
  return <Typography style={lowEmphasisStyle} variant="h5" {...props} />;
};

export const H6 = props => {
  return <Typography variant="h6" {...props} />;
};

export const H6LowEmphasis = props => {
  return <Typography style={lowEmphasisStyle} variant="h6" {...props} />;
};

export const Subtitle1 = props => {
  return <Typography variant="subtitle1" {...props} />;
};

export const Subtitle1White = props => {
  return <Typography style={whiteStyle} variant="subtitle1" {...props} />;
};

export const Subtitle1MediumEmphasis = props => {
  return (
    <Typography style={mediumEmphasisStyle} variant="subtitle1" {...props} />
  );
};

export const Body1HighEmphasis = props => {
  return <Typography variant="body1" {...props} />;
};

export const Body1Narrow = props => {
  return <Typography variant="body1" style={narrowStyle} {...props} />;
};

export const Body1Spaced = props => {
  return <Typography variant="body1" style={spacedStyle} {...props} />;
};

export const Body1White = props => {
  return <Typography style={whiteStyle} variant="body1" {...props} />;
};

export const Body1MediumEmphasis = props => {
  return <Typography style={mediumEmphasisStyle} variant="body1" {...props} />;
};

export const Body2 = props => {
  return <Typography variant="body2" {...props} />;
};

export const Body2White = props => {
  return <Typography style={whiteStyle} variant="body2" {...props} />;
};

export const Body2MediumEmphasis = props => {
  return <Typography variant="body2" style={mediumEmphasisStyle} {...props} />;
};

export const Body2MediumEmphasisSpaced = props => {
  return (
    <Typography
      variant="body2"
      style={{ ...mediumEmphasisStyle, ...spacedStyle }}
      {...props}
    />
  );
};

export const Body2LowEmphasis = props => {
  return <Typography variant="body2" style={mediumEmphasisStyle} {...props} />;
};

export const Overline = props => {
  return <Typography variant="overline" {...props} />;
};

export const OverlineMediumEmphasis = props => {
  return (
    <Typography style={mediumEmphasisStyle} variant="overline" {...props} />
  );
};

export const CaptionLowEmphasis = props => {
  return <Typography style={lowEmphasisStyle} variant="caption" {...props} />;
};
