import React from "react";
import ExperienceService from "../../../domain/experiences/ExperienceService";
import { ExperiencesLinks } from "./ExperiencesLinks";

class ExperiencesLinksContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experiences: []
    };
  }

  async componentDidMount() {
    const workService = new ExperienceService();
    const experiences = await workService.getAllExperiences();
    this.setState({
      experiences: experiences.filter(experience => {
        return experience.id !== this.props.actualExperience;
      })
    });
  }

  render() {
    return (
      <div>
        {this.state.experiences.length > 0 ? (
          <ExperiencesLinks experiences={this.state.experiences} />
        ) : null}
      </div>
    );
  }
}

export default ExperiencesLinksContainer;
