export const primary = "#01c1f0";

export const secondary = "#ec248f";

export const surface = "#ffffff";

export const black85 = "rgba(0, 0, 0, 0.85)";

export const black50 = "rgba(0, 0, 0, 0.5)";

export const black35 = "rgba(0, 0, 0, 0.35)";
