import logoSrc from "./imgs/rayuela-icon.svg";
import header1Src from "./imgs/header1.jpg";
import header2Src from "./imgs/header2.jpg";
import header3Src from "./imgs/header3.jpg";
import grid1Src from "./imgs/grid1.jpg";
import grid2Src from "./imgs/grid2.jpg";
import grid3Src from "./imgs/grid3.jpg";
import grid4Src from "./imgs/grid4.jpg";
import grid5Src from "./imgs/grid5.jpg";

import Experience, { ACADEMIC_EXPERIENCE_TYPE } from "../Experience";

const rayuelaTags = `
Mobile Interaction Design, Editoral Design, Visual Design
`;

const rayuelaDescription = `
<p>
This transmedia project combines the reading experience with a mobile application that complements the hypertextuality 
of the book without necessarily disrupting the reading process. As the book contains a significative amount of references 
related with topics such as art, music, philosophy and geography, we wanted to amplify the narrative by providing a way to access and track these references. 
</p>
<p>
The printed book contains QR codes that when discovered, can be scanned to unlock content such as audio, video, images 
and geolocalisation information. 
The mobile app also encourages the reader to choose a non-linear narrative and track the experience together with the 
content that is discovered along the way. Readers are also able to share the discovered content accross different 
social networks as a way to further amplify the hypertextual and multi-platform experience.
</p>
`;

const rayuelaHeadline = `
Rayuela is a world-famous novel and an icon of latinoamerican literature. In his book, Julio Cortázar introduced a 
non-linear narrative that allows the reader to find different paths to experience the story. This case study leverages 
the hypertextual narrative from the book to transform it into an immersive transmedia experience.
`;

const rayuelaHighlight = `
The project was developed with Mariana Sánchez for the Interface Design course in the Media Art and Design MFA 
from the Bauhaus Universität Weimar.
`;

const rayuelaHeaderCaption = `
App splash, reading mode selection and printed book.
`;

const rayuelaData = {
  id: "rayuela",
  type: ACADEMIC_EXPERIENCE_TYPE,
  color: "#f4553a",
  icon: logoSrc,
  title: "Rayuela",
  subtitle: "Transmedia Experience",
  tags: rayuelaTags,
  headline: rayuelaHeadline,
  highlight: rayuelaHighlight,
  description: rayuelaDescription,
  headerCaption: rayuelaHeaderCaption,
  headerPictures: [header1Src, header2Src, header3Src],
  gridPictures: [grid1Src, grid2Src, grid3Src, grid4Src, grid5Src],
  video: "https://vimeo.com/154118240"
};

const rayuela = new Experience();
rayuela.fromData(rayuelaData);

export default rayuela;
