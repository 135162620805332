import ExperienceService from "../../../domain/experiences/ExperienceService";
import React, { useEffect } from "react";
import { Work } from "./Work";

export const loadExperiencesAsync = async (workService, setExperiences) => {
  const works = await workService.getWorkExperiences();
  const academic = await workService.getAcademicExperiences();

  setExperiences({ works, academic });
};

// Container, uses react hooks to provide data
const WorkContainer = () => {
  const workService = new ExperienceService();

  const [experiences, setExperiences] = React.useState({
    works: [],
    academic: []
  });

  useEffect(() => {
    loadExperiencesAsync(workService, setExperiences);
  }, [workService, setExperiences]);

  return <Work works={experiences.works} academic={experiences.academic} />;
};
export default WorkContainer;
