import React from "react";
import {
  Body1HighEmphasis,
  Body2MediumEmphasis,
  H3,
  H5MediumEmphasis,
  H6LowEmphasis
} from "../../shared/styleguide/textStyles";
import Grid from "@material-ui/core/Grid";
import WithCaption from "../../shared/withCaption/WithCaption";
import ExperiencesLinksContainer from "../../shared/experiencesLink/ExperiencesLinksContainer";

import Parser from "html-react-parser";
import PlainStyleNavLink from "../../shared/plainStyleLink/plainStyleNavLink";
import Box from "@material-ui/core/Box";
import HighlightOnHoverLink from "../../shared/links/HighlightOnHoverLink";

const textGridSpacing = 3;
const picturesGridSpacing = 1;
const topSpacing = 2;
const singleColumnSizes = {
  xs: 12,
  md: 8
};
const threePicturesSizes = {
  xs: 12,
  sm: 4
};
const twoPicturesSizes = {
  xs: 12,
  sm: 6
};

export const WorkBreadcrumb = ({ children }) => {
  const breadcrumbH3Style = {
    marginBottom: "0px",
    lineHeight: "1",
    marginLeft: "0.5rem"
  };

  return (
    <Box mb={1}>
      <Grid container direction="row" alignItems="flex-end">
        <PlainStyleNavLink to="/work">
          <H6LowEmphasis>Work |</H6LowEmphasis>
        </PlainStyleNavLink>
        <H3 style={breadcrumbH3Style}>{children}</H3>
      </Grid>
    </Box>
  );
};

export const BigGridImage = ({ src, alt }) => {
  const gridImgStyle = {
    width: "100%",
    backgroundColor: "#f5f5f5"
  };
  return <img src={src} alt={alt} style={gridImgStyle} />;
};

export const GridImage = ({ src, alt }) => {
  const gridImgContainerStyle = {
    position: "relative",
    width: "100%",
    paddingBottom: "100%"
  };

  const gridImgStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#f5f5f5"
  };
  return (
    <div style={gridImgContainerStyle}>
      <img src={src} alt={alt} style={gridImgStyle} />
    </div>
  );
};

export const Column = ({ children }) => (
  <Box mt={topSpacing}>
    <Grid container spacing={textGridSpacing}>
      <Grid item {...singleColumnSizes}>
        {children}
      </Grid>
    </Grid>
  </Box>
);

export const ThreeImagesInline = ({ caption, images }) => (
  <Box mt={topSpacing}>
    <WithCaption caption={caption}>
      <Grid container spacing={picturesGridSpacing}>
        <Grid item {...threePicturesSizes}>
          <GridImage src={images[0]} alt="Header picture" />
        </Grid>
        <Grid item {...threePicturesSizes}>
          <GridImage src={images[1]} alt="Header picture" />
        </Grid>
        <Grid item {...threePicturesSizes}>
          <GridImage src={images[2]} alt="Header picture" />
        </Grid>
      </Grid>
    </WithCaption>
  </Box>
);

export const FiveImagesGrid = ({ caption, images }) => (
  <Box mt={topSpacing}>
    <WithCaption caption={caption}>
      <Grid mt={5} container spacing={picturesGridSpacing}>
        <Grid item {...threePicturesSizes}>
          <GridImage src={images[0]} alt="Header picture" />
        </Grid>
        <Grid item {...threePicturesSizes}>
          <GridImage src={images[1]} alt="Header picture" />
        </Grid>
        <Grid item {...threePicturesSizes}>
          <GridImage src={images[2]} alt="Header picture" />
        </Grid>
      </Grid>
      <Grid container spacing={picturesGridSpacing}>
        <Grid item {...twoPicturesSizes}>
          <BigGridImage src={images[3]} alt="Header picture" />
        </Grid>
        <Grid item {...twoPicturesSizes}>
          <BigGridImage src={images[4]} alt="Header picture" />
        </Grid>
      </Grid>
    </WithCaption>
  </Box>
);

export const Link = ({ href, children }) => (
  <Grid item>
    <HighlightOnHoverLink href={href}>{children}</HighlightOnHoverLink>
  </Grid>
);

export const Links = ({ children }) => (
  <Box mt={topSpacing}>
    <Grid container spacing={3}>
      {children}
    </Grid>
  </Box>
);

const WorkDetail = ({ work }) => {
  return (
    <div>
      <WorkBreadcrumb>{work.title}</WorkBreadcrumb>

      <Column>
        <H5MediumEmphasis>{Parser(work.subtitle)}</H5MediumEmphasis>
      </Column>

      <ThreeImagesInline
        caption={work.headerCaption}
        images={work.headerPictures}
      />

      <Column>
        <Body1HighEmphasis>{Parser(work.headline)}</Body1HighEmphasis>
        <Body2MediumEmphasis>{Parser(work.description)}</Body2MediumEmphasis>
      </Column>

      <FiveImagesGrid caption={work.highlight} images={work.gridPictures} />

      <Links>
        {work.video ? <Link href={work.video}>Watch Video</Link> : null}
        {Object.keys(work.caseStudies).map(key => (
          <Link href={work.caseStudies[key]}>{key}</Link>
        ))}
      </Links>

      <Box mt={topSpacing}>
        <ExperiencesLinksContainer actualExperience={work.id} />
      </Box>
    </div>
  );
};

export default WorkDetail;
