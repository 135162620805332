import Grid from "@material-ui/core/Grid";
import React from "react";
import Hidden from "@material-ui/core/Hidden";

const style = {
  marginBottom: "2.5rem"
};

export const ExperienceContainer = ({ title, children }) => {
  return (
    <Grid container spacing={3} style={style}>
      <Hidden smDown>
        <Grid item xs={3}>
          {title}
        </Grid>
      </Hidden>
      <Grid item xs={12} md={9}>
        {children}
      </Grid>
    </Grid>
  );
};
