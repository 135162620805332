import { Link } from "react-router-dom";
import React from "react";
import { H6LowEmphasis } from "../styleguide/textStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

export const ExperiencesLinks = ({ experiences }) => {
  const experiencesLinksStyle = {
    display: "flex"
  };

  const divider = <H6LowEmphasis>&nbsp;|&nbsp;</H6LowEmphasis>;

  return (
    <Box mt={4}>
      <Grid container style={experiencesLinksStyle}>
        {experiences
          .map((experience, index) => (
            <Grid item>
              <Link to={experience.id} style={{ textDecoration: "none" }}>
                <H6LowEmphasis>{experience.title}</H6LowEmphasis>
              </Link>
            </Grid>
          ))
          .reduce((prev, curr) => [prev, divider, curr])}
      </Grid>
    </Box>
  );
};
