import React from "react";
import { ExperienceContainer } from "../sharedComponents";
import Typography from "@material-ui/core/Typography";
import { H5 } from "../../../../shared/styleguide/textStyles";
import {primary} from "../../../../shared/styleguide/colors";

const Education = () => {
  return (
    <ExperienceContainer title={<H5 style={{ color: primary }}>Education</H5>}>
      <div>
        <Typography variant="h6">
          Master Degree in Software Engineering
        </Typography>
        <Typography variant="h7">University of L'Aquila</Typography>
        <Typography>
          Computer Science and Mathematics Department (DISIM)
        </Typography>
        <Typography variant="subtitle1">2017 - 110/110 cum Laude</Typography>
      </div>
      <br />

      <div>
        <Typography variant="h6">
          Research Fellowship “beContent Framework Refactoring”
        </Typography>
        <Typography variant="h7">University of L'Aquila</Typography>
        <Typography>
          Computer Science and Mathematics Department (DISIM)
        </Typography>
        <Typography variant="subtitle1">2013</Typography>
      </div>
      <br />

      <div>
        <Typography variant="h6">
          Bachelor Degree in Software Engineering
        </Typography>
        <Typography variant="h7">University of L'Aquila</Typography>
        <Typography>
          Computer Science and Mathematics Department (DISIM)
        </Typography>
        <Typography variant="subtitle1">2013 - 110/110</Typography>
      </div>
    </ExperienceContainer>
  );
};

export default Education;
