import React from "react";
import Headline from "./headline/Headline";
import Info from "./info/Info";
import Divider from "../../shared/divider/Divider";
import QuickLinks from "../../shared/contacts/QuickLinks";

const Home = () => {
  return (
    <div>
      <Headline />
      <Divider />
      <Info />
      <QuickLinks />
    </div>
  );
};

export default Home;
