import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { primary } from "../../shared/styleguide/colors";

const Title = () => {
  return (
    <Typography variant="h3" style={{ color: primary }}>
      Laura Agudelo Guzman
    </Typography>
  );
};

const ContactDetails = () => {
  return (
    <Typography align="right" variant="subtitle2">
      Baierbrunner Str. 77, 81379, München <br />
      +49 XXX XXXXXXX • agudelo41@gmail.com
    </Typography>
  );
};

const ActualRole = () => {
  return <Typography variant="h4">Product Designer</Typography>;
};

const Quote = () => {
  return (
    <Typography align="right" variant="subtitle2">
      <i>
        I’m a Product Designer with eight years of experience, focused on
        integrating visual design, interaction design and user research
        practices. My mission is to understand the challenges, needs and
        motivations from users, and translate them into intuitive and impactful
        products and experiences.
      </i>
    </Typography>
  );
};

const verticalMargin = "20px";

const CVHeader = () => {
  return (
    <div>
      <Grid
        container
        alignItems="center"
        direction="row"
        style={{ marginBottom: verticalMargin, marginTop: verticalMargin }}
      >
        <Grid item xs={5}>
          <Title />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
          <ContactDetails />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="flex-start"
        direction="row"
        style={{ marginBottom: verticalMargin, marginTop: verticalMargin }}
      >
        <Grid items xs={6}>
          <ActualRole />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid items xs={5}>
          <Quote />
        </Grid>
      </Grid>
    </div>
  );
};

export default CVHeader;
