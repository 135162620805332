import React from "react";
import Grid from "@material-ui/core/Grid";
import HighlightOnHoverNavLink from "../links/HighlightOnHoverNavLink";

const QuickLinks = () => {
  return (
    <Grid container spacing={3}>
      <Grid item>
        <HighlightOnHoverNavLink to="/work">
          Work
        </HighlightOnHoverNavLink>
      </Grid>
      <Grid item>
        <HighlightOnHoverNavLink to="/about">
          About
        </HighlightOnHoverNavLink>
      </Grid>
    </Grid>
  );
};

QuickLinks.propTypes = {};

export default QuickLinks;
